/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import Chat from "./chat"

import "../assets/css/mma-styles.css"

const Layout = ({ children, splash }) => {
  const layouClass = "mma-theme home"

  return (
    <div>
      <div className={layouClass} id="page">
        <div className="header-holder">
          <div className="header-holder__wrap">
            <Header />
            {splash}
          </div>
        </div>
        {children}
        <Footer />
      </div>
      <Chat />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
