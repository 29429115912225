import React from "react"
// images
import SteveImg from "../../assets/images/steve.jpg"

const HomeAbout = () => (
  <div className="info-section" id="about">
    <div className="container">
      <h3 id="about-title">Who the heck are we?</h3>
      <div className="info-section__wrap">
        <aside className="column-seo">
          <figure>
            <img src={SteveImg} alt="Steve Ginsberg" />
          </figure>
          <h4>
            <strong>Steve Ginsberg</strong> | Founder &amp; CEO
          </h4>
          <div className="column-seo__btn-holder">
            <a
              href="https://www.linkedin.com/in/steveginsberg/"
              target="_blank"
              rel="noopener"
            >
              <i className="icon icon_linkedin">linkedin</i>
            </a>
          </div>
        </aside>
        <section className="info-section__content">
          <p>
            Steve has been running successful online marketing campaigns for
            medical practices for almost 20 years. He was one of the original
            employees for one of the largest digital agencies in the U.S., and
            as the #1 consultant worldwide – out of 1100+ employees in 16
            countries – Steve helped expand the business rapidly, with an IPO on
            the NASDAQ in 2010. He started Legal Marketing Arm in 2011, with the
            mission of providing only the best possible service and results for
            law firms who want to scale, without the red tape.
          </p>
          <p>
            <em>
              <strong>
                “I’m proud that I’ve been able to change the lives of so many
                physicians over the years. After working at the largest
                marketing agencies, in-house at a small practice, and as a
                consultant, I know exactly what it takes to win, in the most
                competitive U.S. markets. We’re nothing like the sales-driven
                “churn and burn” companies (we all know who), or the fly-by
                night, predatory service providers that are so prevalent today.
                With us, there’s no B.S., fluff, or unrealistic guarantees. All
                we care about is consistently driving you qualified, inbound
                leads and new patients from Google and the internet, at the
                lowest cost-per-acquisition…or you don’t pay.”
              </strong>
            </em>
          </p>
        </section>
      </div>
    </div>
  </div>
)

export default HomeAbout
