import React from "react"
import { Link } from "gatsby"

const Do = () => (
  <div className="new-marketing-section" id="do">
    <div className="container">
      <header className="new-marketing-section__head">
        <h2>
          Search Engine Marketing &amp; Lead Generation for Doctors Who Want
          More Leads, More Patients and More Growth.
        </h2>
      </header>
      <div className="new-marketing-section__content">
        <ul className="list_newmarketing">
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_mgoogle">Google Ads Management</i>
              </span>
              <h6>Google Ads Management</h6>
              <p>
                Strategic, profitable search engine marketing and lead
                generation campaigns on Google Adwords.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_mbing">Bing Ads Management</i>
              </span>
              <h6>Bing Ads Management</h6>
              <p>
                Get the most out of Bing &amp; Yahoo paid search marketing, and
                have all the major search engines covered.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_seo">Search Engine Optimization</i>
              </span>
              <h6>Search Engine Optimization</h6>
              <p>
                Rank on page 1 of Google and Bing for keywords that drive the
                most leads and sales.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_mwebsites">
                  Websites and Landing Pages
                </i>
              </span>
              <h6>Conversion Rate Optimization</h6>
              <p>
                Website &amp; landing page development and design so you can
                convert the most of your web traffic.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_madvertising">Display Advertising</i>
              </span>
              <h6>Display Advertising</h6>
              <p>
                Keep your company's ads in front of potential customers across
                millions of websites, on all devices.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_mretargeting">Retargeting</i>
              </span>
              <h6>Retargeting</h6>
              <p>
                Keep your company's ads and brand in front of potential clients
                who have already visited your website
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_geofencing">Geofencing</i>
              </span>
              <h6>Geofencing</h6>
              <p>
                Target your ads to people on their mobile phones based on the
                exact address or location they're at.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_pay">Pay-Per-Call</i>
              </span>
              <h6>Pay-Per-Call</h6>
              <p>
                Exclusive, live-transfer phone call leads from people actively
                looking for your services.
              </p>
            </div>
          </li>
          <li>
            <div className="box-newmarketing">
              <span className="icon-holder">
                <i className="icon icon_mconversion">
                  Conversion Rate Optimization
                </i>
              </span>
              <h6>Conversion Rate Optimization</h6>
              <p>
                Increase leads and revenue by A/B testing website &amp; landing
                page copy and design.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Do
