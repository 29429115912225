import React from "react"
import Layout from "../components/layout-index"
import SEO from "../components/seo"
// homepageComponents
import HomeTestimonials from "../components/homepage/testimonials"
import HomeSplash from "../components/homepage/splash"
import HomeAbout from "../components/homepage/about"
import HomeServices from "../components/homepage/services"
import HomeDo from "../components/homepage/do"
import Partners from "../components/general/promo-partners"
import SeenIcons from "../components/general/seen-icons"
import MainForm from "../components/forms/main-form"
import CallAction from "../components/general/call-action"

const IndexPage = () => (
  <Layout splash={<HomeSplash />}>
    <SEO title="Medical Marketing Arm" />
    <Partners />
    <HomeTestimonials />
    <HomeAbout />
    <HomeServices />
    <HomeDo />
    <div className="mobile-wrap">
      <div className="contact-section">
        <div className="container">
          <div className="contact__wrap">
            <MainForm />
            <section className="contact__content">
              <CallAction />
              <SeenIcons />
            </section>
          </div>
        </div>
      </div>
      <Partners />
    </div>
  </Layout>
)

export default IndexPage
